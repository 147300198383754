import { ReactElement } from 'react';
import { Typography, Box } from '@mui/material';

import { AdminLayout } from '~/layouts';
import { useAuthProvider } from '~/hooks';
import { Searchbar } from '~/layouts/Admin/components';

export const Dashboard = (): ReactElement => {
  const { userInfo } = useAuthProvider();

  return (
    <AdminLayout
      title="Admin Dashboard"
    >
      <Box mt={4} mb={4}>
        <Typography align="center" variant="h2">
          Hej {userInfo?.user?.firstname}, what would you like to do?
        </Typography>
        <Searchbar size="medium" />
      </Box>
    </AdminLayout>
  );
};
