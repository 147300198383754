import { useState, useEffect, useCallback, ReactElement } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Logo } from '@localstack/ui';
import { useApiEffect, useRoutes, UserService } from '@localstack/services';
import { Box, Card, CardContent, Container, Link, Typography } from '@mui/material';

import { useAuthProvider } from '~/hooks';
import { BASE_URL, AppRoute } from '~/config';
import { BaseLayout } from '~/layouts';
import { storeAuthToken } from '~/util/storage';

export const SSOCallback = (): ReactElement => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Optional<string>>(null);
  const [goodToGo, setGoodToGo] = useState(false);

  const [searchParams] = useSearchParams();
  const { reloadUserInfo } = useAuthProvider();
  const { goto } = useRoutes();

  const { finalizeSso } = useApiEffect(UserService, ['finalizeSso'], { suppressErrors: true });

  const code = searchParams.get('code');

  const authenticate = useCallback(
    async () => {
      try {
        setError(null);
        setLoading(true);

        const redirect_uri = `${BASE_URL}${AppRoute.SSO_CALLBACK}`;

        const response = await finalizeSso({
          code: code || '',
          redirect_uri,
        });

        storeAuthToken(response);

        const userInfo = await reloadUserInfo();

        setGoodToGo(true);
        
        if (userInfo && userInfo.user.signup_survey?.require_finish_account_creation) {
          goto(AppRoute.SIGN_UP, undefined, 'isFinishAccountCreation=true');
        } 
        else goto(AppRoute.DASHBOARD);
      } catch (e) {
        setError(e.message ?? 'Unknown error');
      } finally {
        setLoading(false);
      }
    },
    [code],
  );

  useEffect(() => {
    if (!code) return;
    authenticate();
  }, []);

  return (
    <BaseLayout documentTitle="SSO Login" hideNavigation>
      <Box flexGrow={1}>
        <Container>
          <Box mt={3}>
            <Card>
              <CardContent>
                <Box textAlign="center">
                  <Logo />
                </Box>
                {!code && (
                  <Box textAlign="center" mt={2}>
                    <Typography color="error">
                      Something went wrong during OAuth Process
                    </Typography>
                  </Box>
                )}
                {error && (
                  <Box textAlign="center" mt={2}>
                    <Typography color="error">{error}</Typography>
                  </Box>
                )}
                {loading && code && (
                  <Box textAlign="center" mt={2}>
                    Please wait while we are finalising your login...
                  </Box>
                )}
                {goodToGo && (
                  <Box textAlign="center" mt={2}>
                    <Typography>
                      We are all set! You will be redirected to the dashboard shortly...{' '}
                      If this does not happen, please click{' '}
                      <Link href={AppRoute.DASHBOARD} underline="hover">on this link</Link>
                    </Typography>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Box>
        </Container>
      </Box>
    </BaseLayout>
  );
};
