import { QueueAttributeMap } from 'aws-sdk/clients/sqs';
import { TrailInfo } from 'aws-sdk/clients/cloudtrail';
import { GetSchemaVersionResponse } from 'aws-sdk/clients/glue';
import { ResourceRecordSet } from 'aws-sdk/clients/route53';
import { HistoryEventExecutionDataDetails } from 'aws-sdk/clients/stepfunctions';

import { MagicExpandableRows } from './localstack';

// s3
export {
  CreateBucketRequest as CreateS3BucketRequest,
  Bucket as S3Bucket,
  Object as S3Object,
  ListObjectsV2Request as ListS3ObjectsV2Request,
  DeleteObjectsRequest as DeleteS3ObjectsRequest,
  GetBucketCorsOutput as GetS3BucketCorsRequest,
  PutBucketCorsRequest as PutS3BucketCorsRequest,
} from 'aws-sdk/clients/s3';

export type S3CreateFolderArgs = {
  Name: string
}

export type S3EmptyBucketFolderArgs = {
  Bucket: string,
  Prefix: string
}

export type PutS3BucketCorsFormRequest = {
  Bucket: string,
  CORSConfiguration: string
}

// lambda
export {
  FunctionConfiguration as LambdaFunctionConfig,
  FunctionCode as LambdaFunctionCode,
  FunctionCodeLocation as LambdaFunctionCodeLocation,
  FunctionVersion as LambdaFunctionVersion,
  CreateFunctionRequest as CreateLambdaFunctionRequest,
  GetFunctionResponse as GetLambdaFunctionResponse,
  EventSourceMappingConfiguration as EventSourceMapping,
  LayersListItem as LambdaLayersList,
  PublishLayerVersionRequest as CreateLambdaLayerRequest,
  LayerVersionsList as LamdaLayerVersionsList,
  InvocationRequest as LamdaFunctionInvocationRequest,
  InvocationResponse as LambdaFunctionInvocationResponse,
} from 'aws-sdk/clients/lambda';

// backup
export type {
  BackupJob,
  BackupRule,
  StartBackupJobInput,
  CreateBackupVaultOutput,
  GetBackupSelectionOutput as BackupSelection,
  GetBackupPlanOutput as BackupPlan,
  UpdateBackupPlanInput as UpdateBackupPlanRequest,
  CreateBackupSelectionInput as CreateBackupSelectionRequest,
  CreateBackupPlanInput as CreateBackupPlanRequest,
  BackupVaultListMember as BackupVault,
  CreateBackupVaultInput as CreateBackupVaultRequest,
  RecoveryPointByBackupVault as VaultRecoveryPoint,
  RecoveryPointByResource as ResourceRecoveryPoint,
  StartBackupJobInput as StartBackupJobRequest,
  StartRestoreJobInput as StartRestoreJobRequest,
  ProtectedResource as ProtectedBackupResource,
} from 'aws-sdk/clients/backup';

// dynamodb
export type {
  TableDescription as DynamoDBTable,
  CreateTableInput as CreateDynamoDBTableRequest,
  UpdateTableInput as UpdateDynamoDBTableRequest,
  GlobalSecondaryIndexDescription as DynamoDBGlobalSecondaryIndex,
  ScanInput as ScanDynamoDBRequest,
  QueryInput as QueryDynamoDBRequest,
  ExecuteStatementInput as ExecuteDynamoDBStatementRequest,
  AttributeMap as DynamoDBAttributeMap,
  AttributeValue as DynamoDBAttributeValue,
  AttributeDefinition as DynamoDBAttributeDefinition,
  KeySchemaElement as DynamoDBKeySchemaElement,
} from 'aws-sdk/clients/dynamodb';

// rds
export type {
  DBInstance as RDSInstance,
  DBCluster as RDSCluster,
  CreateDBInstanceMessage as CreateRDBParams,
  ModifyDBInstanceMessage as UpdateRDBParams,
  CreateDBClusterMessage as CreateClusterParams,
  ModifyDBClusterMessage as UpdateClusterParams,
  GlobalCluster as RDSGlobalCluster,
  CreateGlobalClusterMessage as CreateGlobalRDSCluster,
} from 'aws-sdk/clients/rds';

export type RDSDatabase = {
  id: string;
  DBidentifier?: string | undefined;
  Status?: string | undefined;
  Type?: string | undefined;
  Engine?: string | undefined;
  Region?: string | undefined;
  Size?: string | undefined;
}

export type RDSDatabaseExpandableType = RDSDatabase & MagicExpandableRows;

export {
  ExecuteStatementRequest as RDSQueryParams,
  ExecuteStatementResponse as RDSQueryResults,
  Field as RDSQueryField,
} from 'aws-sdk/clients/rdsdataservice';

export type {
  CreateTableRequest as CreateTimestreamTableRequest,
  CreateDatabaseRequest as CreateTimestreamDatabaseRequest,
  Database as TimestreamDatabase,
  Table as TimestreamTable,
  Records as TSRecords,
  WriteRecordsResponse,
} from 'aws-sdk/clients/timestreamwrite';

export type { QueryResponse } from 'aws-sdk/clients/timestreamquery';
// iam
export {
  Group as IAMGroup,
  CreateGroupRequest as CreateIAMGroupRequest,
  UpdateGroupRequest as UpdateIAMGroupRequest,
  User as IAMUser,
  CreateUserRequest as CreateIAMUserRequest,
  UpdateUserRequest as UpdateIAMUserRequest,
  Role as IAMRole,
  CreateRoleRequest as CreateIAMRoleRequest,
  UpdateRoleRequest as UpdateIAMRoleRequest,
  Policy as IAMPolicy,
  CreatePolicyRequest as CreateIAMPolicyRequest,
  AttachRolePolicyRequest as AttachIAMRolePolicyRequest,
  AttachedPolicy as AttachedIAMPolicy,
  PutRolePolicyRequest as PutIAMRolePolicyRequest,
  GetRolePolicyResponse as IAMInlineRolePolicy,
} from 'aws-sdk/clients/iam';

export type IAMRolePolicy = {
  PolicyName: string | undefined;
  PolicyArn: string | undefined;
  PolicyType: string;
}

// api gateway v1
export {
  RestApi as AgwRestApi,
  CreateRestApiRequest as CreateAgwRestApiRequest,
  UpdateRestApiRequest as UpdateAgwRestApiRequest,
  Resource as AgwRestApiResource,
  CreateResourceRequest as CreateAgwRestApiResourceRequest,
  UpdateResourceRequest as UpdateAgwRestApiResourceRequest,
  DeleteResourceRequest as DeleteAgwRestApiResourceRequest,
  Method as AgwRestApiMethod,
  PutMethodRequest as CreateAgwRestApiMethodRequest,
  UpdateMethodRequest as UpdateAgwRestApiMethodRequest,
  Authorizer as AgwRestApiAuthorizer,
  CreateAuthorizerRequest as CreateAgwRestApiAuthorizerRequest,
  UpdateAuthorizerRequest as UpdateAgwRestApiAuthorizerRequest,
  RequestValidator as AgwRestApiRequestValidator,
  CreateRequestValidatorRequest as CreateAgwRestApiRequestValidatorRequest,
  UpdateRequestValidatorRequest as UpdateAgwRestApiRequestValidatorRequest,
  Integration as AgwRestApiIntegration,
  PutIntegrationRequest as CreateAgwRestApiIntegrationRequest,
  UpdateIntegrationRequest as UpdateAgwRestApiIntegrationRequest,
  MethodResponse as AgwRestApiMethodResponse,
  PutMethodResponseRequest as CreateAgwRestApiMethodResponseRequest,
  UpdateMethodResponseRequest as UpdateAgwRestApiMethodResponseRequest,
  DeleteMethodResponseRequest as DeleteAgwRestApiMethodResponseRequest,
  IntegrationResponse as AgwRestApiIntegrationResponse,
  PutIntegrationResponseRequest as CreateAgwRestApiIntegrationResponseRequest,
  UpdateIntegrationResponseRequest as UpdateAgwRestApiIntegrationResponseRequest,
  DeleteIntegrationResponseRequest as DeleteAgwRestApiIntegrationResponseRequest,
  Model as AgwRestApiModel,
  CreateModelRequest as CreateAgwRestApiModelRequest,
  UpdateModelRequest as UpdateAgwRestApiModelRequest,
  Deployment as AgwRestApiDeployment,
  CreateDeploymentRequest as CreateAgwRestApiDeploymentRequest,
  UpdateDeploymentRequest as UpdateAgwRestApiDeploymentRequest,
  Stage as AgwRestApiStage,
  CreateStageRequest as CreateAgwRestApiStageRequest,
  UpdateStageRequest as UpdateAgwRestApiStageRequest,
  ApiKey as AgwRestApiKey,
  CreateApiKeyRequest as CreateAgwRestApiKeyRequest,
  DeleteApiKeyRequest as DeleteAgwRestApiKeyRequest,
  UsagePlan as AgwRestApiUsagePlan,
  CreateUsagePlanRequest as CreateAgwRestApiUsagePlanRequest,
  UpdateUsagePlanRequest as UpdateAgwRestApiUsagePlanRequest,
  DeleteUsagePlanRequest as DeleteAgwRestApiUsagePlanRequest,
} from 'aws-sdk/clients/apigateway';

// api gateway v2
export {
  Api as AgwApi,
  CreateApiRequest as CreateAgwApiRequest,
  UpdateApiRequest as UpdateAgwApiRequest,
  DeleteApiRequest as DeleteAgwApiRequest,
  Deployment as AgwApiDeployment,
  CreateDeploymentRequest as CreateAgwApiDeploymentRequest,
  Stage as AgwApiStage,
  CreateStageRequest as CreateAgwApiStageRequest,
  UpdateStageRequest as UpdateAgwApiStageRequest,
  DeleteStageRequest as DeleteAgwApiStageRequest,
  Route as AgwApiRoute,
  CreateRouteRequest as CreateAgwApiRouteRequest,
  UpdateRouteRequest as UpdateAgwApiRouteRequest,
  DeleteRouteRequest as DeleteAgwApiRouteRequest,
  Authorizer as AgwApiAuthorizer,
  CreateAuthorizerRequest as CreateAgwApiAuthorizerRequest,
  UpdateAuthorizerRequest as UpdateAgwApiAuthorizerRequest,
  DeleteAuthorizerRequest as DeleteAgwApiAuthorizerRequest,
  Integration as AgwApiIntegration,
  CreateIntegrationRequest as CreateAgwApiIntegrationRequest,
  UpdateIntegrationRequest as UpdateAgwApiIntegrationRequest,
  DeleteIntegrationRequest as DeleteAgwApiIntegrationRequest,
  Model as AgwApiModel,
  CreateModelRequest as CreateAgwApiModelRequest,
  UpdateModelRequest as UpdateAgwApiModelRequest,
  DeleteModelRequest as DeleteAgwApiModelRequest,
} from 'aws-sdk/clients/apigatewayv2';

// CloudFormation
export {
  StackSummary as CloudFormationStack,
  StackResourceSummary as CloudFormationResourceSummary,
  CreateStackInput as CreateCloudFormationStackRequest,
  UpdateStackInput as UpdateCloudFormationStackRequest,
} from 'aws-sdk/clients/cloudformation';

// Cognito
export {
  SchemaAttributeType as CognitoPoolAttribute,
  AttributeType as CognitoUserAttribute,
  AdminGetUserResponse as CognitoUser,
  UserPoolType as CognitoPool,
  AdminCreateUserRequest as CognitoCreateUserParams,
  AdminUpdateUserAttributesRequest as CognitoUpdateUserParams,
  CreateUserPoolRequest as CognitoCreatePoolParams,
  UpdateUserPoolRequest as CognitoUpdatePoolParams,
  GroupType as CognitoGroup,
  CreateGroupRequest as CreateCognitoGroupRequest,
  UpdateGroupRequest as UpdateCognitoGroupRequest,
  AdminAddUserToGroupRequest as AddCognitoUserToGroupRequest,
} from 'aws-sdk/clients/cognitoidentityserviceprovider';

// CloudWatch Logs
export {
  LogGroup as CloudWatchLogGroup,
  LogStream as CloudWatchLogStream,
  FilteredLogEvent as CloudWatchLogEvent,
  CreateLogGroupRequest as CreateCloudWatchLogGroupRequest,
  CreateLogStreamRequest as CreateCloudWatchLogStreamRequest,
} from 'aws-sdk/clients/cloudwatchlogs';

// sas
export {
  SendEmailRequest as SendSESEmailRequest,
  VerifyEmailIdentityRequest as VerifySESV1EmailIdentityRequest,
} from 'aws-sdk/clients/ses';

// SES v2
export {
  IdentityInfo as SESV2IdentityInfo,
  CreateEmailIdentityRequest as CreateSESV2EmailIdentityRequest,
  SendEmailRequest as SendSESV2EmailRequest,
} from 'aws-sdk/clients/sesv2';

// secrets-manager
export {
  SecretListEntry as SMSecret,
  GetSecretValueResponse as SMSecretValue,
  CreateSecretRequest as SMSecretCreateParams,
  UpdateSecretRequest as SMSecretUpdateParams,
} from 'aws-sdk/clients/secretsmanager';

// Step Functions
export {
  ExecutionListItem as ExecutionItem,
  StateMachineListItem as StateMachineItem,
  HistoryEvent as ExecutionEvent,
  CreateStateMachineInput as CreateStateMachineRequest,
  UpdateStateMachineInput as UpdateStateMachineRequest,
  DescribeStateMachineOutput as DescribeStateMachine,
  StartExecutionInput as StartExecutionRequest,
  DescribeExecutionOutput as StateMachineExecution,
  HistoryEventType as StateMachineExecutionEventType,
} from 'aws-sdk/clients/stepfunctions';

export type StateMachineCustomExecutionEvent = {
  Id: number;
  Type: string;
  Step: string;
  Resource: string;
  StartedAfter: string;
  DateAndTime: Date;
  EventDetails: StateMachineExecutionEventDetails;
}

export type StateMachineExecutionEventDetails = {
  name?: string;
  resourceType?: string;
  input?: string;
  output?: string;
  inputDetails?: HistoryEventExecutionDataDetails
  outputDetails?: HistoryEventExecutionDataDetails;
  resource?: string;
  region?: string;
  parameters?: string;
  // More keys to be added as per need as this is a custom type
}

export type StateMachineExecutionGroupedEvent = {
  Name?: string;
  Type?: string;
  Status?: string;
  StartedAfter?: string;
  Duration?: string;
  // Columns which won't show in the table
  Data: StateMachineCustomExecutionEvent[];
  Definition?: StateMachineDefinitionState;
  Input?: string;
  Output?: string;
  Resource?: string;
}

export type StateMachineDefinition = {
  StartAt: string;
  States: StateMachineDefinitionStates;
}

export type StateMachineDefinitionStates = Record<string, StateMachineDefinitionState>;

type StateMachineDefinitionState = {
  Type: string;
  Branches?: StateMachineDefinition[];
  Resource?: string;
  Choices?: unknown[]
  Next?: string;
  End?: boolean;
  OutputPath?: string;
  Parameters?: Record<string, unknown>;
  Retry?: unknown[];
  // More keys to be added as per need as this is a custom type
}

// SSM
export {
  PutParameterRequest as SSMPutRequest,
  ParameterMetadata as SSMParameter,
} from 'aws-sdk/clients/ssm';

// AppSync
export {
  CreateGraphqlApiRequest as CreateAppSyncGraphqlApiRequest,
  UpdateGraphqlApiRequest as UpdateAppSyncGraphqlApiRequest,
  GraphqlApi as AppSyncGraphqlApi,
  Type as AppSyncType,
  CreateTypeRequest as CreateAppSyncTypeRequest,
  UpdateTypeRequest as UpdateAppSyncTypeRequest,
  Resolver as AppSyncResolver,
  CreateResolverRequest as CreateAppSyncResolverRequest,
  UpdateResolverRequest as UpdateAppSyncResolverRequest,
  StartSchemaCreationRequest as AppSyncStartSchemaCreationRequest,
  DataSource as AppSyncDataSource,
  CreateDataSourceRequest as CreateAppSyncDataSourceRequest,
  UpdateDataSourceRequest as UpdateAppSyncDataSourceRequest,
  FunctionConfiguration as AppSyncFunction,
  CreateFunctionRequest as CreateAppSyncFunctionRequest,
  UpdateFunctionRequest as UpdateAppSyncFunctionRequest,
  ApiKey as AppSyncApiKey,
  CreateApiKeyRequest as CreateAppSyncApiKeyRequest,
  UpdateApiKeyRequest as UpdateAppSyncApiKeyRequest,
} from 'aws-sdk/clients/appsync';

// CloudWatch Metrics
export {
  Metric as CloudWatchMetric,
  PutMetricDataInput as PutCloudWatchMetricRequest,
  Datapoint as CloudWatchMetricDatapoint,
  MetricAlarm as CloudWatchMetricAlarm,
  PutMetricAlarmInput as PutCloudWatchMetricAlarmRequest,
  Statistic as CloudWatchMetricStatistic,
  ComparisonOperator as CloudWatchComparisonOperator,
  GetMetricStatisticsInput as CloudWatchGetMetricStatisticsRequest,
  GetMetricStatisticsOutput as CloudWatchMetricStatistics,
} from 'aws-sdk/clients/cloudwatch';

// ElastiCache
export {
  CacheCluster as ElastiCacheCluster,
  CreateCacheClusterMessage as CreateElastiCacheClusterParams,
  ModifyCacheClusterMessage as ModifyElastiCacheClusterParams,
} from 'aws-sdk/clients/elasticache';

// KMS
export {
  KeyListEntry as KMSKey,
  KeyMetadata as KMSKeyMetadata,
  CreateKeyRequest as CreateKSMKeyParams,
  UpdateKeyDescriptionRequest as UpdateKMSKeyDescriptionParams,
} from 'aws-sdk/clients/kms';

// export type KMSKeyMetadata = KeyMetadata;
// export type KMSKeyExpanded = KMSKey & KMSKeyMetadata;

// Kinesis
export {
  CreateStreamInput as CreateKinesisStreamParams,
  UpdateStreamModeInput as UpdateKinesisStreamModeParams,
  StreamDescription as KinesisStream,
} from 'aws-sdk/clients/kinesis';

// Kafka
export {
  CreateClusterRequest as CreateKafkaClusterParams,
  UpdateClusterConfigurationRequest as UpdateKafkaClusterConfigurationParams,
  ClusterInfo as KafkaCluster,
} from 'aws-sdk/clients/kafka';

// Event Bridge
export {
  Rule as EventBridgeRule,
  PutRuleRequest as PutEventBridgeRuleRequest,
  EventBus as EventBridgeBus,
  CreateEventBusRequest as CreateEventBridgeEventBusRequest,
  PutEventsRequest as PutEventBridgeEventsRequest,
  Target as EventBridgeTarget,
  PutTargetsRequest as PutEventbridgeTargetsRequest,
} from 'aws-sdk/clients/eventbridge';

// ESC/Fargate
export {
  Cluster as ECSCluster,
  CreateClusterRequest as CreateECSClusterParams,
  UpdateClusterRequest as UpdateECSClusterParams,
  Task as ECSTask,
  RunTaskRequest as RunECSTaskParams,
  Service as ECSService,
  CreateServiceRequest as CreateECSServiceParams,
  UpdateServiceRequest as UpdateECSServiceParams,
  TaskDefinition as ECSTaskDefinition,
  RegisterTaskDefinitionRequest as RegisterECSTaskDefinitionParams,
} from 'aws-sdk/clients/ecs';

export type {
  Subscription as SNSSubscription,
  SubscriptionAttributesMap as SNSSubscriptionAttributes,
  Topic as SNSTopic,
  TopicAttributesMap as SNSTopicAttributes,
  GetSubscriptionAttributesResponse as SubscriptionAttributes,
  SubscribeInput as CreateSNSSubscriptionRequest,
  CreateTopicInput as CreateSNSTopicRequest,
  PublishInput as SNSMessagePublishRequest,
} from 'aws-sdk/clients/sns';

export type SQSQueue = {
  Name?: string,
  QueueUrl: string,
  attributes?: QueueAttributeMap
}

export type {
  CreateQueueRequest as CreateSQSQueueRequest,
  SendMessageRequest as SendSQSMessageRequest,
  Message as SQSMessage,
  QueueAttributeMap as SQSQueueAttributes,
} from 'aws-sdk/clients/sqs';

export interface SharedHookProps {
  isLoading: boolean;
  isError: boolean;
  error?: Error;
}

export type {
  DataCatalog as AthenaDataCatalog,
  DataCatalogSummary as AthenaDataCatalogSummary,
  CreateDataCatalogInput as CreateAthenaDataCatalogRequest,
  UpdateDataCatalogInput as UpdateAthenaDataCatalogRequest,
  Database as AthenaDatabase,
  GetQueryResultsOutput as AthenaQueryResults,
} from 'aws-sdk/clients/athena';

// cloudtrail
export type CloudTrail = TrailInfo & { IsLogging?: boolean }
export { Event as CloudTrailEvent, CreateTrailRequest } from 'aws-sdk/clients/cloudtrail';

// ec2
export {
  Instance as EC2Instance,
  RunInstancesRequest as RunEC2InstancesRequest,
} from 'aws-sdk/clients/ec2';

// elb
export {
  LoadBalancerDescription as LoadBalancer,
  CreateLoadBalancerListenerInput as CreateLoadBalancer,
} from 'aws-sdk/clients/elb';

// elbv2
export {
  LoadBalancer as LoadBalancerInput,
  CreateLoadBalancerInput as CreateLoadBalancerV2,
} from 'aws-sdk/clients/elbv2';

// ecr
export {
  Repository as ECRDescribeRepositories,
  CreateRepositoryRequest as CreateECRRepositoryRequest,
  ImageDetail as ECRDescribeImages,
  PutImageRequest as CreateECRImageRequest,
} from 'aws-sdk/clients/ecr';

// MQ
export {
  BrokerSummary as MQBrokerSummary,
  CreateBrokerRequest as MQCreateBrokerRequest,
  DescribeBrokerResponse as MQDescribeBroker,
} from 'aws-sdk/clients/mq';

// Glue
export {
  Database as GlueDatabase,
  CreateDatabaseRequest as CreateGlueDatabaseRequest,
  CreateTableRequest as CreateGlueTableRequest,
  UpdateTableRequest as UpdateGlueTableRequest,
  Table as GlueTable,
  Connection as GlueConnection,
  CreateConnectionRequest as CreateGlueConnectionRequest,
  UpdateConnectionRequest as UpdateGlueConnectionRequest,
  Crawler as GlueCrawler,
  CreateCrawlerRequest as CreateGlueCrawlerRequest,
  UpdateCrawlerRequest as UpdateGlueCrawlerRequest,
  Job as GlueJob,
  CreateJobRequest as CreateGlueJobRequest,
  UpdateJobRequest as UpdateGlueJobRequest,
  JobRun as GlueJobRun,
  RegistryListItem as GlueRegistryListItem,
  CreateRegistryInput as CreateGlueRegistryRequest,
  UpdateRegistryInput as UpdateGlueRegistryRequest,
  SchemaListItem as GlueSchema,
  GetSchemaResponse as GlueSchemaItem,
  CreateSchemaInput as CreateGlueSchemaRequest,
  UpdateSchemaInput as UpdateGlueSchemaRequest,
  SchemaVersionListItem as GlueSchemaVersion,
  RegisterSchemaVersionInput as RegisterGlueSchemaVersionRequest,
  Partition as GluePartition,
  MetadataInfoMap as GlueMetadataInfoMap,
  MetadataInfo as GlueMetadataInfo,
  PutSchemaVersionMetadataInput as PutGlueSchemaVersionMetadataRequest,
  MetadataKeyValuePair as GlueMetadataKeyValuePair,
  GetSchemaVersionResponse as GlueSchemaVersionItem,
} from 'aws-sdk/clients/glue';

export {
  DBInstance as NeptuneInstance,
  DBCluster as NeptuneCluster,
  CreateDBClusterMessage as CreateNDBCluster,
  ModifyDBClusterMessage as UpdateNDBCluster,
  CreateDBInstanceMessage as CreateNDBInstance,
  ModifyDBInstanceMessage as UpdateNDBInstance,
} from 'aws-sdk/clients/neptune';

export type GlueSchemaDefinitionItem = Pick<GetSchemaVersionResponse, 'SchemaDefinition' | 'VersionNumber'>;

// sagemaker
export {
  ModelSummary as SGMModels,
  CreateModelInput as CreateSGMModelRequest,
  EndpointSummary as SGMEndpoints,
  CreateEndpointInput as CreateSGMEndpointRequest,
  EndpointConfigSummary as SGMEndpointConfigs,
  CreateEndpointConfigInput as CreateSGMEndpointConfigRequest,
} from 'aws-sdk/clients/sagemaker';

// AplicationAutoscaling
export {
  ScalableTarget as AAScalableTarget,
  RegisterScalableTargetRequest as RegisterScalableTargetsRequest,
} from 'aws-sdk/clients/applicationautoscaling';

// Route53
export {
  HostedZone as Route53HostedZone,
  CreateHostedZoneRequest as CreateRoute53HostedZoneRequest,
  ChangeResourceRecordSetsRequest as ChangeRoute53ResourceRecordSetsRequest,
} from 'aws-sdk/clients/route53';

// Route53Resolver
export {
  ResolverEndpoint as Route53ResolverEndpoint,
  CreateResolverEndpointRequest,
  UpdateResolverEndpointRequest,
  GetResolverEndpointResponse,
  ResolverEndpoint,
} from 'aws-sdk/clients/route53resolver';

// CloudFront
export {
  DistributionSummary as CloudFrontDistributionList,
  CreateDistributionRequest as CreateCloudFrontDistributionRequest,
  Distribution as DistributionDetails,
  UpdateDistributionRequest as UpdateDistributionParams,
} from 'aws-sdk/clients/cloudfront';

export type Route53ResourceRecordSet = ResourceRecordSet & { RoutingPolicy?: string }

// FIS
export {
  StartExperimentRequest as FisStartExperimentRequest,
  CreateExperimentTemplateRequest as FisExperimentTemplate,
  ExperimentSummary as FisExperimentSummary,
  ExperimentTemplateSummary as FisExperimentTemplateSummary,
  TagMap as FisTags,
  CreateExperimentTemplateActionInput as FisExperimentTemplateAction,
} from 'aws-sdk/clients/fis';

// OpenSearch
export {
  DomainInfo as OpenSearchListDomainNamesRequest,
  CreateDomainRequest as OpenSearchCreateDomainRequest,
  DomainConfig as OpenSearchDomainConfig,
  DomainStatus as OpenSearchDomainStatus,
  UpdateDomainConfigRequest as OpenSearchUpdateDomainConfigRequest,
} from 'aws-sdk/clients/opensearch';

// QLDB
export {
  LedgerSummary as QLDBLedgerSummary,
  CreateLedgerRequest as QLDBCreateLedgerRequest,
  DescribeLedgerResponse as QLDBDescribeLedgerResponse,
  UpdateLedgerRequest as QLDBUpdateLedgerRequest,
} from 'aws-sdk/clients/qldb';

// DocDB
export {
  DBCluster,
  DBInstance,
  DBInstanceMessage,
  DBClusterMessage,
  CreateDBClusterMessage,
  ModifyDBClusterMessage,
  CreateDBInstanceMessage,
  ModifyDBInstanceMessage,
} from 'aws-sdk/clients/docdb';

export interface DocDBTableRow extends MagicExpandableRows {
  DBIdentifier: string;
  Status: string;
  Type: string;
  Engine: string;
  Size: string;
}
// AppConfig
export {
  CreateApplicationRequest as AppConfigCreateApplicationRequest,
  UpdateApplicationRequest as AppConfigUpdateApplicationRequest,
  Applications as AppConfigApplications,
  Application as AppConfigApplication,
} from 'aws-sdk/clients/appconfig';

// Cognito Identity
export {
  CreateIdentityPoolInput,
  IdentityPoolsList,
  IdentityPool,
} from 'aws-sdk/clients/cognitoidentity';

// acm
export {
  RequestCertificateRequest,
  ListCertificatesResponse,
  DescribeCertificateResponse,
  UpdateCertificateOptionsRequest,
} from 'aws-sdk/clients/acm';

// mwaa
export {
  CreateEnvironmentInput,
  ListEnvironmentsOutput,
  GetEnvironmentOutput,
  UpdateEnvironmentInput,
  Environment,
} from 'aws-sdk/clients/mwaa';

// amplify
export {
  CreateAppRequest,
  ListAppsResult,
  GetAppResult,
  UpdateAppRequest,
  App as AmplifyApp,
  GetBackendEnvironmentResult,
  ListBackendEnvironmentsResult as ListBackendEnvironments,
  BackendEnvironment,
  CreateBackendEnvironmentRequest,
} from 'aws-sdk/clients/amplify';

// eks
export {
  CreateClusterRequest,
  ListClustersResponse,
  DescribeClusterResponse,
  UpdateClusterConfigRequest,
} from 'aws-sdk/clients/eks';

// transcribe
export {
  ListTranscriptionJobsResponse,
  GetTranscriptionJobResponse,
  StartTranscriptionJobRequest,
} from 'aws-sdk/clients/transcribeservice';

// codecommit
export {
  CreateRepositoryInput,
  ListRepositoriesOutput,
  GetRepositoryOutput,
} from 'aws-sdk/clients/codecommit';

// costexplorer
export {
  CreateCostCategoryDefinitionRequest,
  ListCostCategoryDefinitionsResponse,
  DescribeCostCategoryDefinitionResponse,
  UpdateCostCategoryDefinitionRequest,
} from 'aws-sdk/clients/costexplorer';

// account
export {
  AlternateContact,
  PutAlternateContactRequest,
  PutContactInformationRequest,
  GetContactInformationResponse,
  ContactInformation,
} from 'aws-sdk/clients/account';

// dms
export {
  CreateReplicationInstanceMessage,
  CreateEndpointMessage,
  CreateReplicationTaskMessage,
  TestConnectionMessage,
  Endpoint as DMSEndpoint,
  TableStatistics as DMSTableStatistics,
  ReplicationTask as DMSReplicationTask,
  ReplicationInstance as DMSReplicationInstance,
  Connection as DMSConnection,
} from 'aws-sdk/clients/dms';
